/* Change Autocomplete styles in Chrome, Safari &  */

@-webkit-keyframes autofill {
    0%, 100% {
        color: rgba(0, 0, 0, 0.87) !important;
        background: transparent !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color: rgba(0, 0, 0, 0.87) !important;
    transition: background-color 5000000s ease-in-out 0s !important;
    background: transparent !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
    -webkit-animation-delay: 1s !important; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill !important;
    -webkit-animation-fill-mode: both !important;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

/* Global styles */
body {
    background-color: var(--v-synthesis-base);
}

html {
    overflow-y: auto;
}

.center-vertical-horizontal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Custom Fonts */
/* Inter Font */
@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Black.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Bold.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-ExtraBold.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Light.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-ExtraLight.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Medium.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Regular.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-Thin.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/inter/subset-Inter-SemiBold.woff2') format('woff2'),
    url('/fonts/inter/subset-Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

/* Aeonik Font */
@font-face {
    font-family: 'Aeonik Air';
    src: url('/fonts/aeonik/subset-Aeonik-Air.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Air.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik Air';
    src: url('/fonts/aeonik/subset-Aeonik-AirItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-AirItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-BlackItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-Black.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-Bold.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-Light.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-BoldItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-MediumItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-Medium.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-LightItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    letter-spacing: 0.2px;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/fonts/aeonik/subset-Aeonik-Regular.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    letter-spacing: 0.2px;
}

/* Default Vuetify Overrides */
.v-application, body {
    font-family: Aeonik, Roboto, sans-serif;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.2px !important;
}

th[role="columnheader"] {
    white-space: nowrap;
}

.fill-width {
    width: 100%;
}

.fit-content-width {
    width: fit-content;
}

/* v-tabs overrides */
.v-tab {
    font-family: Inter, Aeonik, Roboto, sans-serif;
    font-weight: 600;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 110%;
    text-transform: capitalize !important;
    padding: 6px 8px;
    margin-right: 4px;
    text-align: center;
    border-radius: 4px;
}

.v-tabs .v-slide-group__next, .v-tabs .v-slide-group__prev {
    flex: 0 1 24px;
    min-width: 24px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: var(--v-synthesis-ui-grey-01-base) !important;
}

.v-tab::before,
.v-tab:hover::before {
    border-radius: 4px;
}

.active-tab::before,
.active-tab:hover::before,
.active-tab {
    opacity: 1;
    background-color: var(--v-synthesis-brand-lightest-blue-base);
    border: 1px solid var(--v-synthesis-ui-blue-05-base);
}

.v-tabs-items {
    background-color: initial !important;
}

/* v-text-field border color */
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    color: var(--v-synthesis-ui-grey-02-base);
}

/* v-checkbox border color */
.theme--light.v-input--checkbox .v-icon {
    color: var(--v-synthesis-ui-grey-02-base);
}

.theme--light.v-input--checkbox label {
    color: var(--v-synthesis-brand-dark-blue-base);
    font-size: 16px;
    font-weight: 500;
}

/* Action Button */
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.action-button {
    background-color: var(--v-synthesis-ui-green-05-base) !important;
    color: #FFFFFF !important;
}

.v-btn {
    letter-spacing: 0.2px !important;
}

/* App styles */
.over-percent-limit-warning, .negative-value-input-warning {
    color: var(--v-error-base) !important;
}

.over-percent-limit-warning input, .negative-value-input-warning input {
    color: var(--v-error-base) !important;
    caret-color: var(--v-error-base) !important;
}

.login-bg {
    background-image: url('/img/bg/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.synthesis-text {
    font-family: Aeonik, Roboto, sans-serif;
    letter-spacing: 0.2px;
}

.synthesis-inter-text {
    font-family: Inter, Aeonik, Roboto, sans-serif;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.text-size-10 {
    font-size: 10px !important;
    line-height: 100% !important;
    letter-spacing: 0.5px !important;
}

.text-size-12 {
    font-size: 12px !important;
    line-height: 130% !important;
    letter-spacing: 0.2px !important;
}

.text-size-14 {
    font-size: 14px !important;
    line-height: 130% !important;
    letter-spacing: 0.2px !important;
}

.text-size-16 {
    font-size: 16px !important;
    line-height: 120% !important;
    letter-spacing: 0.2px !important;
}

.text-size-18 {
    font-size: 18px !important;
    line-height: 120% !important;
    letter-spacing: 0.2px !important;
}

.text-size-20 {
    font-size: 20px !important;
    line-height: 110% !important;
    letter-spacing: 0.2px !important;
}

.text-size-22 {
    font-size: 22px !important;
    line-height: 120% !important;
    letter-spacing: 0.2px !important;
}

.text-size-24 {
    font-size: 24px !important;
    line-height: 120% !important;
    letter-spacing: 0.2px !important;
}

.text-size-28 {
    font-size: 28px !important;
    line-height: 120% !important;
}

.text-size-32 {
    font-size: 32px !important;
    line-height: 120% !important;
}

.text-size-40 {
    font-size: 40px !important;
    line-height: 120% !important;
}

.text-size-52 {
    font-size: 52px !important;
    line-height: 120% !important;
}

.section-bg-gradient {
    background: rgb(239, 243, 246);
    background: linear-gradient(180deg, rgba(203, 216, 226, 1) 0%, rgba(239, 243, 246, 1) 70px, rgba(242, 245, 248, 1) 100%);
}

.section-bg-gradient-blue {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, var(--v-synthesis-ui-blue-04-base) -120%, rgba(61, 104, 213, 0) 36%);
}

.section-bg-gradient-red {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, var(--v-synthesis-ui-red-02-base) -120%, rgba(61, 104, 213, 0) 36%);
}

.section-bg-gradient-yellow {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, var(--v-synthesis-ui-yellow-02-base) -120%, rgba(61, 104, 213, 0) 36%);
}

.section-bg-gradient-green {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, var(--v-synthesis-ui-green-02-base) -120%, rgba(61, 104, 213, 0) 36%);
}

/* Cards */
.synthesis-card-border {
    border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
    border-radius: 8px !important;
}

.synthesis-card-border.active {
    border: 1px solid var(--v-synthesis-ui-blue-05-base) !important;
}

/* Menus */
.v-menu__content {
    border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

/* Forms */
.field-label {
    font-family: Aeonik, Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
    color: var(--v-synthesis-brand-dark-blue-base);
}

.button-text-underline {
    text-decoration: underline;
    text-underline-offset: 3px;
}

/* Logo animation */
.animate-logo {
    animation: rotation 4s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
